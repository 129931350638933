import httpClient from './index';

const END_POINT = 'auth';

const login = async data =>
    await httpClient.post(`${END_POINT}/otp`, { ...data });

const loginOtp = async data =>
    await httpClient.post(`${END_POINT}/verify-otp`, { ...data });

const logout = () => httpClient.post(`${END_POINT}/logout`, null);

const updateMarketingEmails = async data =>
    httpClient.post(`${END_POINT}/profile`, data);

export { login, loginOtp, logout, updateMarketingEmails };
