import { createRouter, createWebHashHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();
function lazyLoad(view) {
    return () => import(`../views/${view}.vue`);
}

document.title = 'QDS Panel';

const checkAuth = (to, from, next) => {
    const isAuthenticated = cookies.get('token');
    if (to.name === 'home' && isAuthenticated) {
        next('/project');
    }
    if (to.meta.requiresAuth && !isAuthenticated) {
        next('/');
    } else {
        next();
    }
};

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: checkAuth,
            component: () => import('../views/HomeView.vue'),
        },
        {
            path: '/project',
            name: 'project',
            component: lazyLoad('project/ProjectPage'),
            beforeEnter: checkAuth,
            meta: { layout: 'default', title: '', requiresAuth: true },
            props: true,
        },
        {
            path: '/package',
            name: 'package',
            component: lazyLoad('package/ProjectPackage'),
            beforeEnter: checkAuth,
            meta: { layout: 'default', title: 'Finishes', requiresAuth: true },
            props: true,
        },
        {
            path: '/proceed-order',
            name: 'proceed-order',
            component: lazyLoad('proceedOrder/ProceedOrderPage'),
            beforeEnter: checkAuth,
            meta: {
                layout: 'default',
                title: 'Proceed order',
                requiresAuth: true,
            },
            props: true,
        },
    ],
});

export default router;
