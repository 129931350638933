<template>
    <div
        class="main-header z-100 flex flex-col items-stretch justify-center"
        :class="{
            'slide-in-top top-0 bg-qooWhite-7 max-md:sticky': isHeaderVisible,
            'slide-bottom top-0 bg-qooWhite-7 max-md:sticky max-md:translate-y-[-100%]':
                !isHeaderVisible,
        }">
        <div
            class="flex w-full flex-col items-stretch pb-[2.625rem] pt-[3.3125rem] max-md:max-w-full">
            <div
                class="mx-6 mr-4 flex items-center justify-between gap-5 max-md:mr-2.5 max-md:max-w-full max-md:flex-wrap md:mx-16">
                <h2 class="flex items-center justify-start gap-4">
                    <span
                        class="text-xl font-bold"
                        :class="{
                            'cursor-pointer !text-md !font-[500] text-mainBlack-300':
                                $route.meta.title,
                            'max-md:hidden': $route.meta.title,
                        }"
                        @click="$router.push({ name: 'project' })">
                        {{
                            projectName && projectName.project
                                ? projectName.project
                                : 'Project Name'
                        }}
                    </span>
                    <img
                        v-if="$route.meta.title"
                        class="max-md:hidden"
                        src="@/assets/icons/svg/header/arrow.svg" />
                    <img
                        v-if="$route.meta.title"
                        class="rotate-180 cursor-pointer md:hidden"
                        @click="$router.push({ name: 'project' })"
                        src="@/assets/icons/svg/header/arrow.svg" />
                    <span class="text-[1.375rem] font-[700]">
                        {{ $route.meta.title }}
                    </span>
                </h2>
                <div
                    class="flex items-stretch justify-between gap-3 max-md:max-w-full max-md:flex-wrap">
                    <!-- <QooButton
                        type="outlined"
                        :hasSlot="true"
                        icon=""
                        :showText="breakpoints.w > 768 ? true : false"
                        text="Invoices">
                        <documentIcon class="w-[16px]"></documentIcon>
                    </QooButton> -->
                    <QooButton
                        type="outlined"
                        :hasSlot="true"
                        icon=""
                        :showText="breakpoints.w > 768 ? true : false"
                        text="Account"
                        @clickBtn="isAccountModalOpen = true">
                        <profileIcon class="w-[14px]"></profileIcon>
                    </QooButton>
                </div>
            </div>
        </div>
    </div>
    <AccountModal
        :is-open="isAccountModalOpen"
        :user-data="userData"
        @close="isAccountModalOpen = false" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import QooButton from '@/components/base/common/QooButton.vue';
import profileIcon from '../svg/profileIcon.vue';
import documentIcon from '../svg/documentIcon.vue';
import AccountModal from './AccountModal.vue';
import { useStorage } from '@vueuse/core';
import useBreakpoint from '@/composable/useBreakpoint';

const {
    breakpoints,
    screens: { sm },
} = useBreakpoint();

const userData = useStorage('user');
const projectData = useStorage('project');

const isAccountModalOpen = ref(false);
const count = ref(0);
const isHeaderVisible = ref(true);
const lastScrollTop = ref(0);
const showHeader = ref(false);
const handleScroll = () => {
    const currentScrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop.value) {
        isHeaderVisible.value = false;
    } else {
        isHeaderVisible.value = true;
    }
    lastScrollTop.value = currentScrollTop <= 0 ? 0 : currentScrollTop;
};

const projectName = ref({});

onMounted(() => {
    try {
        if (projectData.value) {
            projectName.value = JSON.parse(projectData.value);
        }
    } catch (error) {
        console.log(error);
    }

    window.addEventListener('scroll', handleScroll);
});
</script>

<style lang="scss">
@import 'MainHeader.scss';

.qoo-line {
    transition: 1.2s ease all;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@media screen and (max-width: 768px) {
    .slide-in-top {
        animation: slideInFromTop 0.5s ease-in-out;
    }

    .slide-bottom {
        animation: slideInFromBottom 0.5s ease-in-out;
    }
}
</style>
