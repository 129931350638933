<template>
    <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="aspect-square">
        <g opacity="0.3">
            <path
                d="M13 1L1.00001 13"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round" />
            <path
                d="M1 1L13 13"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round" />
        </g>
    </svg>
</template>
