import { defineStore } from 'pinia';

export const useStore = defineStore('useStore', {
    state: () => ({
        accessToken: '',
        stateMenu: false,
        width: 0,
        height: 0,
        user: {},
        backTitle: '',
        isLoading: '',
        selectedFloor: 0,
        selectedUnit: 0,
    }),
    getters: {
        getBackTitle(state) {
            return state.backTitle;
        },
        getUser(state) {
            return state.user;
        },
        getHeight(state) {
            return state.height;
        },
        getWidth(state) {
            return state.width;
        },
        getStateMenu(state) {
            return state.stateMenu;
        },
    },
    actions: {
        addTodo(todo, index) {
            this.list[index].todos.push(todo);
        },
        mutationer(payload) {
            const keys = Object.keys(payload);
            for (const item of keys) {
                this[item] = payload[item];
            }
        },
        setStateMenu(payload) {
            this.stateMenu = payload;
        },
        setHeight(payload) {
            this.height = payload;
        },
        setWidth(payload) {
            this.width = payload;
        },
        setUser(payload) {
            this.user = payload;
        },
        setBackTitle(state) {
            return this.backTitle;
        },
    },
});
