<template>
    <div class="qooBox-main">
        <MainHeader v-if="$route.meta.layout == 'default'" />
        <router-view
            :class="{
                'md:hide-scrollbar md:!max-h-[calc(100vh_-_153px)] md:overflow-auto':
                    $route.name != 'home',
            }" />
    </div>
    <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue';
import MainHeader from '@/components/header/MainHeader.vue';

import { computed, ref } from 'vue';
import { useStore } from '@/store/loading.js';

const store = useStore();

const isLoading = computed(() => {
    return store.isLoading;
});
</script>
