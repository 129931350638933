<template>
    <Popup
        :is-visible="isOpen"
        @close="$emit('close')"
        title="Account"
        w="421px">
        <template #header>
            <h2 class="flex items-center gap-3">
                <IconProfile class="w-[14px]" />
                <span class="text-[16px] font-bold sm:text-[18px]">
                    Account
                </span>
            </h2>
        </template>
        <template #default>
            <div class="mt-3">
                <!-- ---------- TABLE ---------- -->
                <ul
                    class="rounded-xl border border-mainBlack-100 text-[14px] sm:text-[16px]">
                    <li
                        class="flex items-center border-b border-mainBlack-100 px-4 pb-6 pt-5 sm:px-6">
                        <h3 class="flex-grow">Email</h3>
                        <span class="text-mainBlack-400">
                            {{ userDataObject.email }}
                        </span>
                    </li>
                    <li
                        class="flex items-center border-b border-mainBlack-100 px-4 pb-6 pt-5 sm:px-6 sm:py-5">
                        <h3 class="flex-grow">Phone</h3>
                        <span class="text-mainBlack-400">{{
                            userDataObject.phone || '-'
                        }}</span>
                    </li>
                    <li class="flex items-center px-4 pb-6 pt-5 sm:px-6">
                        <div class="flex-grow pr-2">
                            <h3 class="pb-2">Marketing emails</h3>
                            <p class="text-[12px] leading-4 text-mainBlack-400">
                                Receive updates and deals from QOO and its
                                partners
                            </p>
                        </div>
                        <div>
                            <SwitchButton v-model="receivingMarketingEmail" />
                        </div>
                    </li>
                </ul>
                <!-- ---------- LOGOUT BUTTON ---------- -->
                <button
                    @click="myLogout"
                    class="mt-2 flex w-full items-center justify-center gap-3 rounded-xl border border-mainBlack-100 py-5 font-medium">
                    <IconLogout class="w-[20px]" />
                    <span class="text-[14px] text-[#F94B25] sm:text-[16px]">
                        Log out
                    </span>
                </button>
            </div>
        </template>
    </Popup>
</template>

<script setup>
import Popup from '@/components/base/PopupModalAnimation.vue';
import { computed, ref, watch } from 'vue';
import IconProfile from '@/components/svg/IconProfile.vue';
import IconLogout from '../svg/IconLogout.vue';
import SwitchButton from '@/components/base/common/SwitchButton.vue';
import { useCookies } from 'vue3-cookies';
import { updateMarketingEmails, logout } from '@/services/axios/auth.service';

const { cookies } = useCookies();

const props = defineProps({
    isOpen: {
        type: Boolean,
    },
    userData: {
        type: String,
    },
});

defineEmits(['close']);

const userDataObject = computed(() => {
    return JSON.parse(props.userData);
});

const receivingMarketingEmail = ref(props.userData?.marketing || false);

async function myLogout() {
    await logout();
    cookies.remove('token');
    localStorage.removeItem('user');
    localStorage.removeItem('project');
    location.reload();
}

watch(
    () => receivingMarketingEmail.value,
    async () => {
        await updateMarketingEmails({
            marketing: receivingMarketingEmail.value,
        });
    }
);
</script>
