<template>
    <Switch
        v-model="enabled"
        :class="enabled ? 'bg-primary-1000' : 'bg-mainBlack-100'"
        class="relative inline-flex h-[22px] w-[38px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out">
        <span class="sr-only">{{ title }}</span>
        <span
            aria-hidden="true"
            :class="enabled ? 'translate-x-[18px]' : 'translate-x-0.5'"
            class="pointer-events-none inline-block h-[18px] w-[18px] translate-y-0.5 transform rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out" />
    </Switch>
</template>

<script setup>
import { Switch } from '@headlessui/vue';

defineProps({
    title: {
        type: String,
    },
});

const enabled = defineModel();
</script>
