<template>
    <component
        :is="as"
        @click="$emit('clickBtn')"
        class="flex cursor-pointer items-center justify-center gap-3 rounded-[30px]"
        :class="buttonStyle">
        <img
            v-if="icon && iconLeft && !hasSlot"
            :src="require('@/assets/icons/svg/' + icon)"
            :alt="text"
            class="aspect-square max-w-full shrink-0 overflow-hidden object-contain object-center"
            :class="iconSize" />
        <slot v-if="iconLeft"></slot>
        <div
            v-show="showText"
            class="self-center font-medium leading-5"
            :class="type === 'outlined' ? 'text-mainBlack-1000' : 'text-white'">
            {{ text }}
        </div>
        <img
            v-if="icon && !iconLeft && !hasSlot"
            :src="require('@/assets/icons/svg/' + icon)"
            :alt="text"
            class="aspect-square max-w-full shrink-0 overflow-hidden object-contain object-center"
            :class="iconSize" />
        <slot v-if="!iconLeft"></slot>
    </component>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    as: {
        type: String,
        default: 'button',
        validator: value => ['router-link', 'button', 'a'].includes(value),
    },
    hasSlot: {
        type: Boolean,
        default: false,
    },
    iconSize: {
        type: String,
        default: 'w-6',
    },
    iconLeft: {
        type: Boolean,
        default: true,
    },
    type: {
        type: String,
        default: 'mainBlack',
        validator: value =>
            ['mainBlack', 'primary', 'outlined'].includes(value),
    },
    icon: {
        type: String,
    },
    showText: {
        type: Boolean,
        default: true,
    },
    classList: {
        type: [String],
        default: '',
    },
    paddingStyle: {
        type: [String],
        default: 'px-9 py-[1.125rem] max-md:px-[15px] max-md:py-[15px]',
    },
    text: {
        type: String,
        default: 'button',
    },
});

const buttonStyle = computed(() => {
    switch (props.type) {
        case 'mainBlack':
            return (
                'bg-mainBlack-1000 ' +
                props.classList +
                ' ' +
                props.paddingStyle
            );
        case 'primary':
            return (
                'bg-primary-1000 ' + props.classList + ' ' + props.paddingStyle
            );
        case 'secondary':
            return (
                'bg-secondary-1000 ' +
                props.classList +
                ' ' +
                props.paddingStyle
            );
        case 'outlined':
            return (
                'bg-white border border-mainBlack-100 ' +
                props.classList +
                ' ' +
                props.paddingStyle
            );
        default:
            return (
                'bg-mainBlack-1000 ' +
                props.classList +
                ' ' +
                props.paddingStyle
            );
    }
});
</script>
